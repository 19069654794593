import React, { useState } from 'react';
import { Desktop } from '../../constants/responsive';
import { langMap } from '../../constants/dataMap';
import { mainColor, bespokeMainColor, greyColor } from '../../constants/colors';
import { css } from '@emotion/core';
import { menu } from '../../constants/menu';
import { useIntl, Link, FormattedHTMLMessage } from 'gatsby-plugin-intl';
import { useLocation } from '@reach/router';
import Button from '../Button';
import Language from '../LanguageSwitcher';
import { useAppContext } from '../../context/AppContext';
import { graphql, useStaticQuery } from 'gatsby';
import Blank from '../Blank';
import ReqDemoBtn from '../ReqDemo';
import '../../styles/common.scss';
import '../../styles/header/desktop-menu.scss';
// 目录与对应图片的映射
const menuPicMap = {
  raas: 'raas',
  // 'smart-stock': 'SmartStock',
  // 'stock-trading': 'StockTrading',
  'Fund-Supermarket': 'FundSupermarket',
  'smart-fund': 'smartfund',
  advisory: 'Institutions',
  selection: 'Institutions',

  'mainland-china': 'Professional',
  'hk-overseas': 'Professional',
  'bespoke-investors': 'Bespoke',
  others: 'Others',
  sts: 'sts',
};

// 【product - 二级目录】
const SecDir = ({ name, setMouseOverMenu }) => {
  const intl = useIntl();
  const location = useLocation();
  const isBespoke = location.pathname.split('/')[4] === 'bespoke';
  return (
    <div
      className={`${name === 'bespoke-investors' ? 'bespoke-sec-dir' : ''}`}
      css={css`
        font-size: 14px;
        font-weight: bold;
        line-height: 19px;
        margin-bottom: 10px;
      `}
      onClick={() => {
        if (name === 'bespoke-investors' && !isBespoke) {
          window.location.assign(
            `/${intl.locale}/product/bespoke-investors/bespoke`
          );
        }
      }}
      onMouseOver={() => {
        // 因为只有一个二级目录可以点击，且传入了setMouseOverMenu方法，所以要判断，否则会报错
        if (setMouseOverMenu) {
          setMouseOverMenu(menuPicMap[name]);
          console.log(name, 'mouseover++++');
        }
      }}
      onMouseOut={() => {
        if (setMouseOverMenu) {
          setMouseOverMenu(menuPicMap['others']);
        }
      }}
    >
      {intl.formatMessage({ id: `menu.${name}` })}
    </div>
  );
};
// 【product - 三级目录】
const ThreeLevelDir = ({
  name,
  url,
  setMouseOverMenu,
  goOut = false,
  tagText = '',
  disabled = false,
}) => {
  const intl = useIntl();
  const location = useLocation();
  const activePage = location.pathname.indexOf(url) !== -1;
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        margin-bottom: 9px;
      `}
    >
      <div
        css={css`
          font-size: 14px;
          line-height: 19px;
          font-weight: ${activePage ? 'bold' : 'normal'};
          a,
          a:active,
          a:visited {
            color: ${activePage ? mainColor : 'black'};
          }
          a:hover {
            color: ${mainColor};
          }
          pointer-events: ${disabled ? 'none' : 'auto'};
        `}
        onMouseOver={() => {
          setMouseOverMenu && setMouseOverMenu(menuPicMap[name]);
        }}
        onMouseOut={() => {
          setMouseOverMenu && setMouseOverMenu(menuPicMap['others']);
        }}
      >
        {/* 先判断链接是否跳出本网站 */}
        {goOut ? (
          <a href={url}>
            <span
              css={css`
                color: ${disabled ? greyColor : 'inherit'};
              `}
            >
              {intl.formatMessage({ id: `menu.${name}` })}
            </span>
          </a>
        ) : (
          <Link to={`/${url}`}>
            <span
              css={css`
                color: ${disabled ? greyColor : 'inherit'};
              `}
            >
              {intl.formatMessage({ id: `menu.${name}` })}
            </span>
          </Link>
        )}
      </div>
      {/* tag */}
      {tagText && (
        <div
          css={css`
            height: 15px;
            vertical-align: middle;
            background: #00b2ff;
            border-radius: 7.5px;
            padding: 0 5px;
            margin-left: 5px;
            display: flex;
            align-items: center;

            font-weight: bold;
            font-size: 10px;
            line-height: 21px;
            color: #ffffff;
          `}
        >
          {tagText}
        </div>
      )}
    </div>
  );
};

const DesktopMenu = () => {
  const intl = useIntl();
  const location = useLocation();
  let textColor = 'black';
  const activeColor = mainColor;
  const isBespoke = location.pathname.split('/')[4] === 'bespoke';
  const {
    institutionsFormRef,
    professionalInvestorsFormRef,
    mainLandUrl,
  } = useAppContext();
  const [mouseOverMenu, setMouseOverMenu] = useState('Others');
  const [mouseOverSmallQRCode, setMouseOverSmallQRCode] = useState(false);
  const lang = langMap[intl.locale];
  const data = useStaticQuery(graphql`
    query {
      raas: file(relativePath: { eq: "header/menu/raas.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      FundSupermarket: file(
        relativePath: { eq: "header/menu/fund-supermarket.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      smartfund: file(relativePath: { eq: "header/menu/smartfund.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Institutions: file(relativePath: { eq: "header/menu/institutions.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Bespoke: file(relativePath: { eq: "header/menu/bespoke.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Others: file(relativePath: { eq: "header/menu/others.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sts: file(relativePath: { eq: "header/menu/s-t-s.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <div
      css={css`
        white-space: nowrap;
        position: relative;
        display: none;
        align-items: center;
        ${Desktop} {
          display: flex;
        }
      `}
    >
      {Object.keys(menu).map((title) => {
        const activeTitle = location.pathname.split('/')[2] === title;
        if (title === 'promotions') {
          return (
            <div
              key={title}
              css={css`
                position: relative;
                margin-left: 10px;
              `}
            >
              <Link to={`/${title}`}>
                <div
                  css={css`
                    padding: 20px;
                    font-weight: ${activeTitle
                      ? '700'
                      : isBespoke
                      ? '400'
                      : '500'};
                    color: ${activeTitle ? mainColor : textColor};
                    &:hover {
                      color: ${mainColor};
                    }
                    &:hover + div {
                      opacity: 1;
                      pointer-events: auto;
                    }
                  `}
                >
                  {intl.formatMessage({ id: `menu.${title}` })}
                </div>
              </Link>
            </div>
          );
        }
        if (title === 'blog') {
          return (
            <div
              key={title}
              css={css`
                position: relative;
                margin-left: 10px;
              `}
            >
              <Link to={`/${title}`}>
                <div
                  css={css`
                    padding: 20px;
                    font-weight: ${activeTitle ? '700' : '500'};
                    color: ${activeTitle ? mainColor : textColor};
                    &:hover {
                      color: ${mainColor};
                    }
                    &:hover + div {
                      opacity: 1;
                      pointer-events: auto;
                    }
                  `}
                >
                  {intl.formatMessage({ id: `menu.${title}` })}
                </div>
              </Link>
            </div>
          );
        }
        return (
          <div
            key={title}
            css={css`
              position: relative;
              margin-left: 10px;
            `}
          >
            {/* 【一级目录】 */}
            <div
              css={css`
                padding: ${{
                  en: '20px 10px',
                  'zh-cn': '20px',
                  'zh-hk': '20px',
                }[intl.locale]};
                font-weight: 700;
                cursor: pointer;
                color: ${activeTitle ? activeColor : textColor};
                &:hover {
                  color: ${mainColor};
                }
                &:hover + div {
                  display: block;
                }
              `}
            >
              {intl.formatMessage({ id: `menu.${title}` })}
            </div>
            {/* 【二级目录】 */}
            {title === 'wealthTech' && (
              <div
                css={css`
                  position: absolute;
                  top: 50px;
                  left: 0;
                  // padding: 35px 0 0 0;
                  background: rgb(0, 0, 0, 0);
                  display: none;
                  border-radius: 10px;
                  &:hover {
                    display: block;
                  }
                `}
              >
                <div
                  css={css`
                    ${Desktop} {
                      width: 641px;
                      height: 169px;
                      border-radius: 10px;
                      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
                      background-color: #ffffff;
                      display: flex;
                      justify-content: space-between;
                    }
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      padding: 18px 18px 18px 23px;
                    `}
                  >
                    {/* 【个人用户 目录】 */}
                    <div
                      css={css`
                        margin-right: 55px;
                      `}
                    >
                      <SecDir name='product' />
                      <ThreeLevelDir
                        name='raas'
                        url='product/institutions/advisory'
                        setMouseOverMenu={setMouseOverMenu}
                      />
                      <ThreeLevelDir
                        name='Fund-Supermarket'
                        url='product/institutions/fundsuper'
                        setMouseOverMenu={setMouseOverMenu}
                      />
                      <ThreeLevelDir
                        name='smart-fund'
                        url='product/institutions/smartfund'
                        setMouseOverMenu={setMouseOverMenu}
                      />
                    </div>
                    {/* 【机构用户 目录】 */}
                    <div>
                      <SecDir name='Solutions' />
                      <ThreeLevelDir name='Brokers' disabled />
                      <ThreeLevelDir name='Asset' disabled />
                      <ThreeLevelDir name='Banks' disabled />
                      <ThreeLevelDir name='Insurance' disabled />
                    </div>
                    {/* 【专业投资者 目录】 */}
                    {/*   <div>
                      <SecDir
                        name='bespoke-investors'
                        setMouseOverMenu={setMouseOverMenu}
                      />
                      <ThreeLevelDir
                        name='mainland-china'
                        goOut
                        url={
                          {
                            live: 'https://www.aqumon.com/cn/zh-cn/',
                            test: 'https://test-toc-v5.aqumon.com/cn/zh-cn/',
                          }[process.env.GATSBY_ENV]
                        }
                        setMouseOverMenu={setMouseOverMenu}
                      />
                      <ThreeLevelDir
                        name='hk-overseas'
                        url='product/bespoke-investors/hk-overseas'
                        setMouseOverMenu={setMouseOverMenu}
                      />
                    </div> */}
                  </div>
                  <div
                    css={css`
                      width: 198px;
                      height: 100%;
                      background: ${`url(${data[mouseOverMenu].childImageSharp.fluid.src})`}
                        left top 100% no-repeat border-box border-box;
                      background-size: cover;
                    `}
                  ></div>
                </div>
              </div>
            )}
            {title === 'S-T-S' && (
              <div
                css={css`
                  position: absolute;
                  top: 50px;
                  left: 0;
                  // padding: 35px 0 0 0;
                  background: rgb(0, 0, 0, 0);
                  display: none;
                  border-radius: 10px;
                  &:hover {
                    display: block;
                  }
                `}
              >
                <div
                  css={css`
                    ${Desktop} {
                      width: 390px;
                      height: 134px;
                      border-radius: 10px;
                      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
                      background-color: #ffffff;
                      display: flex;
                      justify-content: space-between;
                    }
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      padding: 18px 18px 18px 23px;
                    `}
                  >
                    {/* 【个人用户 目录】 */}
                    <div css={css``}>
                      <ThreeLevelDir
                        name='Digital'
                        url='product/bespoke-investors/bespoke/'
                      />
                      <ThreeLevelDir name='Mainland' goOut url={mainLandUrl} />
                      <ThreeLevelDir name='App' url='product/aqumon' />
                      <ThreeLevelDir name='fee' url='resources/fee/' />
                    </div>
                  </div>
                  <div
                    css={css`
                      width: 141px;
                      height: 100%;
                      background: ${`url(${data['sts'].childImageSharp.fluid.src})`}
                        left top 100% no-repeat border-box border-box;
                      background-size: cover;
                    `}
                  ></div>
                </div>
              </div>
            )}
            {title !== 'wealthTech' && title !== 'S-T-S' && (
              <div
                css={css`
                  position: absolute;
                  top: 50px;
                  left: 50%;
                  transform: translateX(-50%);
                  display: flex;
                  flex-direction: column;
                  width: 160px;
                  align-items: center;
                  text-align: center;
                  background: white;
                  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.42);
                  border-radius: 10px;
                  padding: 10px 14px;
                  transition: all 0.2s ease;
                  display: none;
                  &:hover {
                    display: block;
                  }
                `}
              >
                {menu[title].map((page) => {
                  const activePage = location.pathname.split('/')[3] === page;
                  return (
                    <div
                      key={page}
                      css={css`
                        a {
                          padding: 5px;
                          font-size: 14px;
                          display: inline-block;
                          font-weight: ${activePage ? 'bold' : 'normal'};
                          color: ${activePage
                            ? `${mainColor} !important`
                            : 'black'};
                        }
                        a:hover {
                          color: ${mainColor};
                        }
                      `}
                    >
                      <Link to={`/${title}/${page}`}>
                        {intl.formatMessage({ id: `menu.${page}` })}
                      </Link>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
      <div
        css={css`
          margin-left: 25px;
        `}
      >
        <ReqDemoBtn />
      </div>
      <div
        css={css`
          margin-left: 30px;
        `}
      >
        {
          // <a href="https://smarthk.aqumon.com/">
          //   <Button text={intl.formatMessage({ id: "menu.login" })} />
          // </a>

          <div>
            <a
              onClick={() => {
                const devOrTestEvnReg = /(^test-)|(^localhost)|(^127)|(^0)/;
                let domain = `https://smarthk.aqumon.com/#/login?lang=${lang}`;
                if (devOrTestEvnReg.test(window.location.hostname)) {
                  // 测试环境
                  domain = `https://test-smarthk.aqumon.com/#/login?lang=${lang}`;
                }
                window.location.href = `${domain}${
                  sessionStorage.qd ? `&qd=${sessionStorage.qd}` : ''
                }`;
              }}
            >
              <span>{intl.formatMessage({ id: 'menu.login1' })}</span>
            </a>
          </div>
        }
      </div>
      <div
        css={css`
          margin-left: 42px;
        `}
      >
        <Language />
      </div>
      {/* <Blank width={[0, 0, 43]} /> */}
      {/* 二维码 - 缩略图 */}
      {
        <img
          width={37}
          css={css`
            cursor: pointer;
            margin-left: 38px;
          `}
          src='/header/small-app-QR-code.png'
          onMouseOver={() => {
            setMouseOverSmallQRCode(true);
          }}
          onMouseOut={() => {
            setMouseOverSmallQRCode(false);
          }}
        />
      }

      {/* 二维码 - 大图 */}
      <div
        css={css`
          visibility: ${mouseOverSmallQRCode ? 'visible' : 'hidden'};
          opacity: ${mouseOverSmallQRCode ? 1 : 0};
          transition: opacity 0.4s;
          position: absolute;
          width: 182px;
          height: 190px;
          background: #ffffff;
          box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.05);
          border-radius: 15px;
          top: 90px;
          right: -75px;
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <h3
          css={css`
            margin: 26px 0 6px;
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
          `}
        >
          {
            { en: 'Download Now', 'zh-cn': '下载APP', 'zh-hk': ' 下載APP' }[
              intl.locale
            ]
          }
        </h3>
        <img src='/icon/app-download-QR-pink.png' width={108} />
      </div>
    </div>
  );
};

export default DesktopMenu;
